import apiConfigs from './apiconfig';
export default {
  //校验手机号和固定电话
  checkPhone(phone) {
    var reg = /^((0\d{2,3}-\d{7,8})|(1[23456789]\d{9}))$/;
    if (reg.test(phone)) {
      return true;
    } else {
      return false;
    }
  },

  checkRequestResult(res) {
    if (res.code == '0000' || res.Code == '0000') {
      return true;
    }
    return false;
  },
  // 本地存储- 获取
  getLocalStorage(key) {
    let value = window.localStorage.getItem(key);
    if (value != 'undefined' && !!value) {
      var d = JSON.parse(value);
      var endTime = new Date().getTime();
      if (!!d.beginTime) {
        if (getDateInterval(d.beginTime, endTime, 'minutes') > parseInt(d.effectiveMin)) {
          window.localStorage.removeItem(key);
          return null;
        } else {
          if (typeof d.data != 'undefined') {
            return d.data;
          } else {
            window.localStorage.removeItem(key);
            return null;
          }
        }
      } else if (d.data) {
        return d.data;
      }
    }
    return null;
  },
  // 本地存储- 存储  并设置过期分钟
  setLocalStorage(key, data, minutes) {
    var d = {};
    d.data = data;
    if (!!minutes) {
      d.beginTime = new Date().getTime();
      d.effectiveMin = minutes;
    }
    d = JSON.stringify(d);
    window.localStorage.setItem(key, d);
  },
  getQueryString(key) {
    const url = new URL(window.location.href);
    return url.searchParams.get(key);
  },
  // 获取url参数方法 含#
  global_getQueryString(name) {
    let url = window.location.href;
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    let params = {};
    paramArr.forEach((param) => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
    });
    return params[name];
  },
  //重新格式化时间，以兼容safari和ie浏览器
  initDateStyle: function (d) {
    if (!!d) {
      return d.replace(new RegExp(/-/gm), '/');
    }
  },
  rendererZhMoney(v) {
    if (isNaN(v)) {
      return v;
    }
    v = Math.round((v - 0) * 100) / 100;
    v = v == Math.floor(v) ? v + '.00' : v * 10 == Math.floor(v * 10) ? v + '0' : v;
    v = String(v);
    var ps = v.split('.');
    var whole = ps[0];
    var sub = ps[1] ? '.' + ps[1] : '.00';
    var r = /(\d+)(\d{3})/;
    while (r.test(whole)) {
      whole = whole.replace(r, '$1' + ',' + '$2');
    }
    v = whole + sub;
    return v;
  },
  debounce(cb, delay) {
    let timer;
    return function (...args) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        cb.call(this, ...args);
      }, delay);
    };
  },
  //倒计时
  initLimitTime(nowTime, endTime, element, opts) {
    if (!opts.code) return;
    var $time = $(element);
    if ($time.get(0) && $time.html().length < 1) {
      var sTime = new Date(nowTime).getTime() || new Date(this.initDateStyle(nowTime)).getTime();
      var eTime = new Date(endTime).getTime() || new Date(this.initDateStyle(endTime)).getTime();
      var nTime = new Date().getTime();
      //获取传入的开始时间与当前时间误差
      var gapTime = sTime - nTime;
      var limitTime = eTime - sTime;
      var timeformat = '{0}:{1}:{2}';
      if (!!opts && !!opts.part && opts.part == 3) {
        timeformat = '<span class="hours">{0}</span>:<span class="minute">{1}</span>:<span class="second">{2}</span>';
      }
      var getTime = function (time) {
        var h =
          parseInt((time / 1000 / 60 / 60) % 24, 10) > 9
            ? parseInt((time / 1000 / 60 / 60) % 24, 10)
            : '0' + parseInt((time / 1000 / 60 / 60) % 24, 10);
        var m =
          parseInt((time / 1000 / 60) % 60, 10) > 9
            ? parseInt((time / 1000 / 60) % 60, 10)
            : '0' + parseInt((time / 1000 / 60) % 60, 10);
        var s =
          parseInt((time / 1000) % 60, 10) > 9
            ? parseInt((time / 1000) % 60, 10)
            : '0' + parseInt((time / 1000) % 60, 10);
        if (!!opts && !!opts.part && opts.part == 3) {
          return (
            '<span class="hours">' +
            h +
            '</span>:<span class="minute">' +
            m +
            '</span>:<span class="second">' +
            s +
            '</span>'
          );
        }
        return h + ':' + m + ':' + s;
      };
      if (limitTime > 0) {
        var timerCode;
        //获取当前页面对应定时器
        timerCode = config.pageConfig.timer[opts.code];
        //判断当前是否有重名定时器，如果有就清除掉
        if (timerCode) clearInterval(timerCode);
        //if (!!window.__limitTimer)clearInterval(window.__limitTimer);
        $time.html(getTime(limitTime));
        timerCode = setInterval(function () {
          //每次都取服务器时间（当前时间+误差时间）
          limitTime = eTime - new Date().getTime() - gapTime;
          if (limitTime <= 0) {
            $time.html(getTime(0));
            clearInterval(timerCode);
            if (!!opts && !!opts.callback) {
              opts.callback();
            }
          } else {
            $time.html(getTime(limitTime));
            //limitTime = limitTime - 1000;
          }
        }, 1000);
      }
    }
  },
  getPriceStyle(p) {
    if (p || p === 0) {
      let price = parseFloat(p).toFixed(2);
      let price1 = price.split('.')[0];
      let price2 = price.split('.')[1];
      return (
        '<span class="common-large-price">' + price1 + '</span>.<span class="common-small-price">' + price2 + '</span>'
      );
    }
  },
  removeFormatStr(str) {
    return (str || '')
      .replace(/\\n\\r/g, '')
      .replace(/\\n/g, '')
      .replace(/\\s/g, '')
      .replace(/\\t/g, '');
  },
  //接口参数转换处理
  SerializeJson(self) {
    var o = {};
    var getClass = function (obj) {
      return Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1];
    };
    var init = function (obj, name) {
      if (getClass(obj) == 'Object') {
        for (var xtem in obj) {
          if (name == null) {
            init(obj[xtem], xtem.toString());
          } else {
            init(obj[xtem], name + '.' + xtem.toString());
          }
        }
      } else if (getClass(obj) == 'Number' || getClass(obj) == 'String' || getClass(obj) == 'Boolean') {
        o[name] = obj;
      } else if (getClass(obj) == 'Array') {
        for (var i = 0; i < obj.length; i++) {
          init(obj[i], name + '[' + i + ']');
        }
      }
    };
    init(self);
    return o;
  },
  getWebsiteUrl(page) {
    //获取M站地址
    let enterpriseId = this.getLocalStorage('EnterpriseId');
    let refId = this.getLocalStorage('RefId');
    let processInstanceId = this.getLocalStorage('processInstanceId');
    let productType = this.getLocalStorage('Page');
    let location = this.getLocalStorage('location');
    let orderNo = this.getLocalStorage('orderNo');
    let plantId = this.getLocalStorage('PlatId');
    let webUrl = 'https://m.ceekee.com/';
    if (plantId == 1005) {
      webUrl = 'https://d.ceekee.com/';
    }
    if (this.getLocalStorage('PlatId') === '1080') {
      // webUrl = 'https://uatmtrip.gdcts.com/';
      webUrl = 'https://mtrip.gdcts.com/';
    }
    let apiConfig;
    if (plantId) {
      apiConfig = apiConfigs.find((x) => x.type == 'thirdService' && x.plantId == plantId);
    } else {
      if (refId) {
        apiConfig = apiConfigs.find((x) => x.refId == refId);
      } else {
        apiConfig = apiConfigs.find((x) => x.enterpriseId == enterpriseId);
      }
    }
    if (apiConfig) {
      webUrl = apiConfig.mwebUrl;
    }

    let userInfo = JSON.parse(localStorage.getItem('_User_Info_') || {});
    let Token = userInfo.data['Token'];
    let MemberId = userInfo.data['MemberId'];
    webUrl += page + '?memberId=' + encodeURIComponent(MemberId) + '&token=' + encodeURIComponent(Token);
    if (refId) {
      webUrl += '&RefId=' + refId;
    }
    if (processInstanceId) {
      webUrl += '&ProcessInstanceId=' + processInstanceId;
    }
    if (productType && !page) {
      webUrl += '&Page=' + productType;
    }
    if (plantId) {
      webUrl += '&platId=' + plantId;
    }
    return webUrl;
  },
  getUrlToken() {
    let webUrl = '';
    let userInfo = JSON.parse(localStorage.getItem('_User_Info_') || {});
    let Token = userInfo.data['Token'];
    let MemberId = userInfo.data['MemberId'];
    let isManage = this.getLocalStorage('_User_Info_').IsManager ? 1 : 0;
    webUrl +=
      '?memberId=' + encodeURIComponent(MemberId) + '&token=' + encodeURIComponent(Token) + '&isManage=' + isManage;
    return webUrl;
  },
  getParams(url) {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;
    while ((match = regex.exec(url))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
    }
    return params;
  },
};
